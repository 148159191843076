<template>
  <div>
    <el-card :body-style="{ padding: '25px' }">
      <div>
        <maca-select-box
          v-model="filtroRol"
          :url="urlRol"
          @change="filtroRolCambiado"
          seleccionarPrimero
        ></maca-select-box>
        <br />
        <div v-loading="cargandoTabla" style="min-height: 100px">
          <div
            class="permisosPolicy"
            v-for="(permiso, index) in permisos"
            :key="index"
          >
            <b>{{ permiso.policy }}</b>
            <div style="display: flex; margin-top: 10px">
              <el-row :gutter="5" style="width: 100%">
                <el-col
                  v-for="(item, index) in permiso.children"
                  :key="index"
                  class="operacion"
                  :sm="5"
                  :xs="24"
                >
                  <el-button
                    :type="item.habilitado ? 'success' : 'danger'"
                    size="default"
                    plain
                    @click="toogleHabilitar(item)"
                    :class="item.habilitado ? 'habilitado' : 'inhabilitado'"
                  >
                    <i
                      :class="
                        item.habilitado ? 'el-icon-check' : 'el-icon-error'
                      "
                    ></i>
                    <span>{{ item.method }}</span>
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<style scoped>
.permisosPolicy {
  background: rgb(237, 240, 247);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 20px;
}
.operacion {
  margin-bottom: 10px;
  padding-left: 7px;
  padding-right: 7px;
  display: flex;
  border-radius: 8px;
}
.operacion .el-button {
  margin: auto;
  width: 100%;
}
</style>

<script>
export default {
  name: "permisos-usuario",
  data() {
    return {
      urlTabla: "/permiso/obtenerTodos",
      urlRol: "/rol/obtenerTodos",
      paramsTabla: {},
      permisos: [],

      cargandoTabla: true,
      bloquearTabla: true,

      filtroRol: null,
    };
  },
  methods: {
    filtroRolCambiado() {
      this.paramsTabla.rolId = this.filtroRol.id;
      this.getTodosPermisos();
    },
    async getTodosPermisos() {
      this.cargandoTabla = true;

      let respuestaApi = await this.$maca.api.get(
        "/permiso/obtenerTodos",
        this.paramsTabla
      );

      this.cargandoTabla = false;

      if (respuestaApi != null) {
        /**
         * tabla:
         *
         * [
         * {policy: "--", children: [
         *     {policy: "--", method: "view", id: ...}
         *     ]}
         * ]
         */
        this.permisos = [];
        respuestaApi.datos.forEach((fila) => {
          let esChildren = false;

          // agregar a children de anterior si sigue siendo el mismo policy
          if (this.permisos.length > 0) {
            let filaAnterior = this.permisos[this.permisos.length - 1];

            if (filaAnterior.policy == fila.policy) {
              filaAnterior.children.push(fila);
              esChildren = true;
            }
          }

          if (!esChildren) {
            this.permisos.push({
              policy: fila.policy,
              children: [fila],
            });
          }
        });
      }
    },
    async toogleHabilitar(fila) {
      this.cargandoTabla = true;

      // Hacer post
      let params = { id: this.filtroRol.id, permisoId: fila.id };
      let respuestaApi = null;

      if (!fila.habilitado) {
        respuestaApi = await this.$maca.api.post("/rol/crearPermiso", params);
      } else {
        respuestaApi = await this.$maca.api.post(
          "/rol/eliminarPermiso",
          params
        );
      }
      //

      this.getTodosPermisos();

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
};
</script>

<style></style>
